import * as React from 'react';
import { AllImage, AllMdxJob, PageContextProps, graphql2job } from '../utils/graphql';
import DefaultLayout from '../components/layouts/default-layout';
import useElementOnScreen from '../utils/useElementOnScreen';
import * as Colors from '../components/Colors';
import { LocalizedLink } from 'gatsby-theme-i18n';
import { useTranslation } from 'react-i18next';
import SvgDownArrow from '../images/icons/downArrow.inline.svg';
import PresentationVideo from '../components/landing/PresentationVideo';
import MeetButton from '../components/buttons/MeetButton';
import Banner from '../components/clients/Banner';
import { PageProps, graphql } from 'gatsby';
import Contracts from '../components/jobs/Contracts';
import Cities from '../components/jobs/Cities';
import { SharedCard } from '../components/Card';

export const query = graphql`
  query {
    allFile(
      filter: { sourceInstanceName: { eq: "images" }, relativeDirectory: { eq: "clients" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
        name
      }
    }
    allMdx(
      filter: { fields: { source: { eq: "jobs" } } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 4
    ) {
      nodes {
        frontmatter {
          title
          cities
          contracts
        }
        fields {
          slug
        }
      }
    }
  }
`;

type OurCurrentSponsorshipQueryProps = {
  allFile: AllImage;
  allMdx: AllMdxJob;
};

type OurCurrentSponsorshipProps = PageProps<OurCurrentSponsorshipQueryProps, PageContextProps>;

const OurCurrentSponsoring: React.VoidFunctionComponent<OurCurrentSponsorshipProps> = ({
  data: {
    allFile: { nodes: nodeFile },
    allMdx: { nodes: nodesJob },
  },
  pageContext,
}) => {
  const { t } = useTranslation('landing');
  const { t: tEvents } = useTranslation('events');
  const [animatedElement, isInView] = useElementOnScreen<HTMLDivElement>();

  const introduction = (text: string) => (
    <div className="pt-5 text-xs text-black md:text-2xl">{text}</div>
  );
  const title = (text: string) => (
    <div className="pt-4 font-bold text-reacteev-blue md:text-5xl">{text}</div>
  );
  const subtitle = (text: string) => (
    <div className="mt-4 font-semibold text-black md:text-3xl">{text}</div>
  );
  const paragraph = (text: string) => <p className="mt-4 text-xs md:text-2xl">{text}</p>;
  const displayFourLeafs = () => {
    const leafToLeft = () => (
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 203 187"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0.5 93V 0.5H 101L 202 93V 186H 101L 0.5 92Z" fill="white" stroke="#020416" />
      </svg>
    );
    const leafToRight = () => (
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 203 188"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0.5 94V 186.5H 101L 202 93V 1H 101L 0.5 93.5Z" fill="white" stroke="#020416" />
      </svg>
    );
    const textOffer = (text: string) => (
      <div className="absolute text-sm font-medium text-reacteev-blue md:text-2xl">{text}</div>
    );
    const displayLeaf = (leafTo: () => JSX.Element, text: string) => (
      <div className="m-[1px] flex items-center justify-center">
        {leafTo()}
        {textOffer(text)}
      </div>
    );
    return (
      <div className="mx-5 mt-4 md:mt-0">
        <div className="flex justify-center">
          {displayLeaf(leafToLeft, tEvents('offers.offers.product'))}
          {displayLeaf(leafToRight, tEvents('offers.offers.agility'))}
        </div>
        <div className="flex justify-center">
          {displayLeaf(leafToRight, tEvents('offers.offers.tech'))}
          {displayLeaf(leafToLeft, tEvents('offers.offers.people'))}
        </div>
      </div>
    );
  };
  const displayJobs = () => {
    return (
      <div className="mx-5 mt-4 md:mt-0">
        {nodesJob.map(graphql2job).map((job) => (
          <SharedCard
            key={job.title}
            title={job.title}
            linkTo={job.slug}
            className="translate-x-0 transform transition-transform duration-700 hover:translate-x-2 "
          >
            <div className="my-3 border border-solid border-black bg-white px-2 py-1">
              <div className="w-max text-sm font-medium text-black md:text-lg">{job.title}</div>
              <div className="text-xs font-medium md:text-base">
                <Contracts contracts={job.contracts} />
                {job.cities.length > 0 && job.contracts.length > 0 ? ', ' : ''}
                <Cities cities={job.cities} />
              </div>
            </div>
          </SharedCard>
        ))}
      </div>
    );
  };

  return (
    <DefaultLayout
      headTitle={tEvents('head-title')}
      headDescription={tEvents('head-description')}
      lang={pageContext.locale}
      theme={{ class: Colors.WHITE_ON_BLUE, pseudoClass: Colors.WHITE_ON_BLUE_PSEUDO_CLASS }}
      preserveNavbarSpace={false}
    >
      <div className="h-screen w-full">
        <header className="relative flex h-screen w-full snap-start flex-wrap justify-center bg-reacteev-blue text-center text-white">
          <div
            className={`translate-y-12 self-center px-4 opacity-0 animate-delay-75 sm:px-8 ${
              isInView ? 'animate-slidein' : ''
            }`}
            ref={animatedElement}
          >
            <h1>{tEvents('header.title')}</h1>
            <h2>{tEvents('header.description')}</h2>
          </div>
          <LocalizedLink
            className="absolute bottom-12 animate-bounce text-center"
            title={t('introduction.discover')}
            to="/our-current-sponsorship/#clients"
          >
            <SvgDownArrow className="mx-auto block" />
            <p className="mt-3 font-medium">{t('introduction.discover')}</p>
          </LocalizedLink>
        </header>
      </div>
      <section id="clients" className="bg-gray-200">
        <Banner clientImages={nodeFile} />
      </section>
      <div className={`${Colors.GRAY_BACKGROUND} px-4 sm:px-8`}>
        <section id="offers" className="container pt-4">
          {introduction(tEvents('offers.introduction'))}
          {title(tEvents('offers.title'))}
          <div className="flex flex-wrap items-center md:pt-5 lg:flex-nowrap">
            {displayFourLeafs()}
            <div className="md:mx-5 md:w-3/4">
              {subtitle(tEvents('offers.subtitle'))}
              {paragraph(tEvents('offers.description1'))}
              {paragraph(tEvents('offers.description2'))}
            </div>
          </div>
          <div className="text-center md:pt-5">
            <MeetButton
              href={tEvents('offers.button.bookingUrl')}
              text={tEvents('offers.button.title')}
              pseudoClass={
                'mx-5 md:mx-0 ' + Colors.BLACK_ON_GRAY + ' ' + Colors.BLACK_ON_GRAY_PSEUDO_CLASS
              }
            />
          </div>
        </section>
        <section id="recrutment" className="container">
          {introduction(tEvents('recrutment.introduction'))}
          {title(tEvents('recrutment.title'))}
          <div className="flex flex-wrap items-center md:pt-5 lg:flex-nowrap">
            {displayJobs()}
            <div className="md:mx-5">
              {subtitle(tEvents('recrutment.subtitle'))}
              {paragraph(tEvents('recrutment.description'))}
            </div>
          </div>
          <div className="text-center md:pt-5">
            <MeetButton
              href={tEvents('recrutment.button.bookingUrl')}
              text={tEvents('recrutment.button.title')}
              pseudoClass={
                'mx-5 md:mx-0 ' + Colors.BLACK_ON_GRAY + ' ' + Colors.BLACK_ON_GRAY_PSEUDO_CLASS
              }
            />
          </div>
        </section>
      </div>
      <div className="relative z-10">
        <PresentationVideo />
      </div>
    </DefaultLayout>
  );
};

export default OurCurrentSponsoring;
